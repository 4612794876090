/*
 * @Description: 
 * @Version: 1.0
 * @Autor: liuhaoyu
 * @Date: 2022-03-16 01:02:43
 * @LastEditors: liuhaoyu
 * @LastEditTime: 2022-03-18 17:20:19
 */
import request from '@/request/request'
// 紧急求援页
// 列表
export function all(data) {
    return request({
        url:'/Property/Rescue/List',
        method:'post',
        data
    })
}
// 详细数据
export function detail(data){
    return request({
        url:'/Property/Rescue/Info',
        method:'post',
        data
    })
}
 